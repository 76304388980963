@import "_common.scss";
.homepage {
  // Spacing between sections that are not stack on-top of eachother.
  > section:not(.no-spacing) + section {
    margin-top: $section-space;
  }

  // Bottom spacing between last section and footer.
  > section:last-of-type {
    margin-bottom: $section-space;
  }

  // On the homepage, we need to uppercase the headline straps. This makes client names uppercase.
  // We can do this because we never show a true pageTitle on the homepage, and if we did,
  // it wouldn't have it's own strap.
  section.headline .strap {
    text-transform: uppercase;
  }

  .video-wrapper {
    background-color: $black;
  }

  &#content > .cards {
    margin-bottom: $section-space;
  }

  @include mobile() {
    .services {
      padding: 3rem 0;

      > .notch {
        height: 10vh;
      }

      ul {
        margin-top: 2rem;
      }

      li {
        margin: 0 2rem;
        padding: 1.2rem 0;
        font-size: 1rem;

        a:after {
          top: 0.3rem;
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
  }

  // Make videos on small wide desktops fit.
  @include nicoleDesktop() {
    .video-section {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      min-height: 360px;
    }
  }
}
